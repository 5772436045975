import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { switchMap, take } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { AuthService } from './auth.service';
import { Subject, Observable, from } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
language:any;
      private sbj = new Subject<any>();
  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
         private authService: AuthService,
  )
  {
    this.language = this.languageService.language();
  }
  pubFilter(data: any) { this.sbj.next(data); }
  obvFilter(): Subject<any> { return this.sbj; }

  getProductCategory(){
    const httpOptions = {
      headers: new HttpHeaders({
       'Authorization': environment.DEFAULT_KEY
      })
    };
    return this.http.get<any>(
      `${environment.APIUrl}/product/category`,httpOptions
    );
  }
  getFlashSale(){
    const httpOptions = {
      headers: new HttpHeaders({
       'Authorization': environment.DEFAULT_KEY
      })
    };
    return this.http.get<any>(
      `${environment.APIUrl}/get-flashsale`,httpOptions
    );
  }
  getProduct(alias,keyword,sort,page,max_price,min_price){
    const httpOptions = {
      headers: new HttpHeaders({
       'Authorization': environment.DEFAULT_KEY
      })
    };
    return this.http.get<any>(
      `${environment.APIUrl}/product/category/${alias}?keyword=${keyword}&sort=${sort}&page=${page}&max_price=${max_price}&min_price=${min_price}`,httpOptions
    );
  }
  getProductFlashSale(alias,keyword,sort,page,max_price,min_price){
    const httpOptions = {
      headers: new HttpHeaders({
       'Authorization': environment.DEFAULT_KEY
      })
    };
    return this.http.get<any>(
      `${environment.APIUrl}/product/category/${alias}?keyword=${keyword}&flashsale=1&sort=${sort}&page=${page}&max_price=${max_price}&min_price=${min_price}`,httpOptions
    );
  }
  getProductById(id){
     const httpOptions = {
      headers: new HttpHeaders({
       'Authorization': environment.DEFAULT_KEY
      })
    };
    return this.http.get<any>(
      `${environment.APIUrl}/product_detail/${id}`,httpOptions
    );


  }


   getMyOrder(page){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
        `${environment.APIUrl}/my_order?page=${page}`,httpOptions
        );
      })
    );

  }
  getNotification(page){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
        `${environment.APIUrl}/notification?page=${page}`,httpOptions
        );
      })
    );

  }
  getProductDetailVariantFlashSale(sku,flashsale_id){
    const httpOptions = {
      headers: new HttpHeaders({
       'Authorization': environment.DEFAULT_KEY
      })
    };
    return this.http.get<any>(
      `${environment.APIUrl}/productflashsale/${sku}/${flashsale_id}`,httpOptions
    );
  }
 getProductDetail(alias){
    const httpOptions = {
      headers: new HttpHeaders({
       'Authorization': environment.DEFAULT_KEY
      })
    };
    return this.http.get<any>(
      `${environment.APIUrl}/product/${alias}`,httpOptions
    );
  }


  getCart(){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.APIUrl}/cart`, httpOptions
        );
      })
    );
  }
  addToCart(qty,slug,flashsale_id){
      return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('qty',qty);
        body.append('slug',slug);
        body.append('flashsale_id',flashsale_id);

        return this.http.post<any>(
          `${environment.APIUrl}/cart/addtocart`, body, httpOptions
        );
      })
    );
  }
 updateCart(id,qty,notes){
      return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('id',id);
          body.append('qty',qty);
          body.append('notes',notes);
        return this.http.post<any>(
          `${environment.APIUrl}/cart/update_cart`, body, httpOptions
        );
      })
    );
 }
    tambahDiskusi(pertanyaan,slug,idParams){
        return this.authService.token.pipe(
          take(1),
          switchMap(token => {

            const httpOptions = {
              headers: new HttpHeaders({
                'Authorization': `Bearer ${token}`
              })
            };
            let body = new FormData();
                      body.append('pertanyaan',pertanyaan);
                      body.append('slug',slug);
                      body.append('idParams',idParams);
            return this.http.post<any>(
              `${environment.APIUrl}/diskusi/add`, body, httpOptions
            );
          })
        );
    }
 ubahAlamat(title,name,address,phone,kecamatan_id,kode_pos,id){
      return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
                  body.append('title',title);
                  body.append('name',name);
                  body.append('address',address);
                  body.append('phone',phone);
                  body.append('kecamatan_id',kecamatan_id);
                  body.append('kode_pos',kode_pos);

        return this.http.post<any>(
          `${environment.APIUrl}/alamat/edit/${id}`, body, httpOptions
        );
      })
    );
 }
 tambahAlamat(title,name,address,phone,kecamatan_id,kode_pos){
      return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
                  body.append('title',title);
                  body.append('name',name);
                  body.append('address',address);
                  body.append('phone',phone);
                  body.append('kecamatan_id',kecamatan_id);
                  body.append('kode_pos',kode_pos);

        return this.http.post<any>(
          `${environment.APIUrl}/user/tambah_alamat`, body, httpOptions
        );
      })
    );
 }
  getAlamat(){
      return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();

        return this.http.post<any>(
          `${environment.APIUrl}/user/get_alamat`, body, httpOptions
        );
      })
    );
 }
 getLocationList(params: any) {
     return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
         body.append('search', params.search);

        return this.http.post<any>(
          `${environment.APIUrl}/getlocation`, body, httpOptions
        );
      })
    );
  }

 deleteCart(id){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('id',id);

        return this.http.post<any>(
          `${environment.APIUrl}/cart/deletecart`, body, httpOptions
        );
      })
    );
  }
}
