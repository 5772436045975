export interface MyEvent {
	event_id: number,
	event_name: string,
	event_image: string,
	event_location: string,
	event_start_date: string,
	event_end_date: string
}

export class User {
	public constructor(
		public id: string,
		public email: string,
		public fullname: string,
		public phone: string,
		public status: string,
		private _token: string
	) {}

	get token() {
		return this._token;
	}
}
