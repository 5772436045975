import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path: 'register',
    loadChildren: () => import('./registration/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./registration/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./registration/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    //      canLoad: [AuthGuard]
  },
  {
    path: 'kurir',
    loadChildren: () => import('./kurir/kurir.module').then( m => m.KurirPageModule),
      canLoad: [AuthGuard]
  },
  {
    path: 'deskripsi-produk',
    loadChildren: () => import('./deskripsi-produk/deskripsi-produk.module').then( m => m.DeskripsiProdukPageModule)
  },
  {
    path: 'detail-produk/:slug',
    loadChildren: () => import('./detail-produk/detail-produk.module').then( m => m.DetailProdukPageModule)
  },

  {
    path: 'shopping-cart',
    loadChildren: () => import('./shopping-cart/shopping-cart.module').then( m => m.ShoppingCartPageModule),
            canLoad: [AuthGuard]
  },
  {
    path: 'pengiriman',
    loadChildren: () => import('./pengiriman/pengiriman.module').then( m => m.PengirimanPageModule),
      canLoad: [AuthGuard]
  },
  {
    path: 'order-history',
    loadChildren: () => import('./order-history/order-history.module').then( m => m.OrderHistoryPageModule),
      canLoad: [AuthGuard]
  },
  {
    path: 'pilih-alamat',
    loadChildren: () => import('./pilih-alamat/pilih-alamat.module').then( m => m.PilihAlamatPageModule),
      canLoad: [AuthGuard]
  },
  {
    path: 'tambah-alamat',
    loadChildren: () => import('./tambah-alamat/tambah-alamat.module').then( m => m.TambahAlamatPageModule),
      canLoad: [AuthGuard]
  },
  {
    path: 'edit-alamat',
    loadChildren: () => import('./edit-alamat/edit-alamat.module').then( m => m.EditAlamatPageModule),
      canLoad: [AuthGuard]
  },
  {
    path: 'pages/:pages_id',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesPageModule)
  },
  {
    path: 'syarat-dan-ketentuan',
    loadChildren: () => import('./page-tnc/page-tnc.module').then( m => m.PageTncPageModule)
  },
  {
    path: 'kebijakan-privasi',
    loadChildren: () => import('./page-kebijakan/page-kebijakan.module').then( m => m.PageKebijakanPageModule)
  },
  {
    path: 'list-produk/:slug',
    loadChildren: () => import('./list-produk/list-produk.module').then( m => m.ListProdukPageModule)
  },
  {
    path: 'beli-langsung',
    loadChildren: () => import('./beli-langsung/beli-langsung.module').then( m => m.BeliLangsungPageModule)
  },
  {
    path: 'pembayaran',
    loadChildren: () => import('./payment/pembayaran/pembayaran.module').then( m => m.PembayaranPageModule),
           canLoad: [AuthGuard]
  },
  {
    path: 'reset-change-password',
    loadChildren: () => import('./reset-change-password/reset-change-password.module').then( m => m.ResetChangePasswordPageModule)
  },
  {
    path: 'my-menu',
    loadChildren: () => import('./my-menu/my-menu.module').then( m => m.MyMenuPageModule),
            canLoad: [AuthGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule),
          canLoad: [AuthGuard]
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./wishlist/wishlist.module').then( m => m.WishlistPageModule),
         canLoad: [AuthGuard]
  },
  {
    path: 'edit-password',
    loadChildren: () => import('./edit-password/edit-password.module').then( m => m.EditPasswordPageModule),
          canLoad: [AuthGuard]
  },
  {
    path: 'konfirmasi-pembayaran',
    loadChildren: () => import('./konfirmasi-pembayaran/konfirmasi-pembayaran.module').then( m => m.KonfirmasiPembayaranPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'cek-invoice',
    loadChildren: () => import('./cek-invoice/cek-invoice.module').then( m => m.CekInvoicePageModule),
      canLoad: [AuthGuard]
  },
  {
    path: 'beri-ulasan',
    loadChildren: () => import('./beri-ulasan/beri-ulasan.module').then( m => m.BeriUlasanPageModule),
      canLoad: [AuthGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then( m => m.EditProfilePageModule),
           canLoad: [AuthGuard]
  },
  {
    path: 'lihat-alamat',
    loadChildren: () => import('./lihat-alamat/lihat-alamat.module').then( m => m.LihatAlamatPageModule),
           canLoad: [AuthGuard]
  },
  {
    path: 'ubah-alamat/:id',
    loadChildren: () => import('./ubah-alamat/ubah-alamat.module').then( m => m.UbahAlamatPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'tambah-diskusi',
    loadChildren: () => import('./tambah-diskusi/tambah-diskusi.module').then( m => m.TambahDiskusiPageModule),
        canLoad: [AuthGuard]
  },
  {
    path: 'lihat-diskusi',
    loadChildren: () => import('./lihat-diskusi/lihat-diskusi.module').then( m => m.LihatDiskusiPageModule)
  },
  {
    path: 'flashsale/:sku/:flashsale_id',
    loadChildren: () => import('./flashsale/flashsale.module').then( m => m.FlashsalePageModule)
  },
  {
    path: 'trending-list',
    loadChildren: () => import('./trending-list/trending-list.module').then( m => m.TrendingListPageModule)
  },
  {
    path: 'not-found',
    loadChildren: () => import('./not-found/not-found.module').then( m => m.NotFoundPageModule)
  },
  {
    path: 'thankyou',
    loadChildren: () => import('./thankyou/thankyou.module').then( m => m.ThankyouPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'not-found'
  },
  {
    path: 'modal-pilihpembayaran',
    loadChildren: () => import('./modal-pilihpembayaran/modal-pilihpembayaran.module').then( m => m.ModalPilihpembayaranPageModule)
  },
 


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
