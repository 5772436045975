import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';

import Swiper from 'swiper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ModalPilihkurirPage} from './modal-pilihkurir/modal-pilihkurir.page';
import { ModalPilihpengirimanPage} from './modal-pilihpengiriman/modal-pilihpengiriman.page';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import {NgxPaginationModule} from 'ngx-pagination';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
 
@NgModule({
  declarations: [AppComponent,ModalPilihkurirPage,ModalPilihpengirimanPage],
  entryComponents: [],
  imports: [HttpClientModule,
            SwiperModule,
            BrowserModule,
            IonicModule.forRoot(),
            AppRoutingModule,
            NgxPaginationModule,
            FormsModule,
              ServiceWorkerModule.register('combined-sw.js'),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
           ],
  providers: [
    StatusBar,
    Swiper,
    SplashScreen,
    Geolocation,
      	LocationAccuracy,
    AndroidPermissions,
    AppVersion,
    
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
    exports: [
    FormsModule,

  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
