import { Component, OnInit } from '@angular/core';
import { Platform, ActionSheetController, AlertController, MenuController, ModalController, LoadingController } from '@ionic/angular';
import { ProductService }  from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService }  from 'src/app/services/cart.service';

@Component({
  selector: 'app-modal-pilihkurir',
  templateUrl: './modal-pilihkurir.page.html',
  styleUrls: ['./modal-pilihkurir.page.scss'],
})
export class ModalPilihkurirPage implements OnInit {
dataCourier:any=[];
userAddressId :any=0;
 constructor(
    public modalController: ModalController,
    private productService: ProductService,
    private route: ActivatedRoute,
    private loadingCtrl: LoadingController,
    private cartService: CartService,
    private alertCtrl: AlertController,
    private router: Router,

    ) {

  }

  ngOnInit() {
    this.loadingCtrl.create({ keyboardClose: true })
      .then(loading => {
        loading.present();
        this.cartService.getCourier().subscribe(res => {
            loading.dismiss();
             this.dataCourier = res.data.dataOngkir;
             this.userAddressId = res.data.user_address_id;
        });
      });
  }
  saveCourier(courierName,price){
       this.loadingCtrl.create({ keyboardClose: true })
      .then(loading => {
        loading.present();
        this.cartService.updateUserCartSettings(this.userAddressId,courierName,'',price).subscribe(res => {
            loading.dismiss();
            this.closeModal();
        });
      });
  }

  async closeModal() {
    await this.modalController.dismiss();
  }


}
