import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { switchMap, take } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { AuthService } from './auth.service';
import { Subject, Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

    language:any;
      private sbj = new Subject<any>();
  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
         private authService: AuthService,
  )
  {
    this.language = this.languageService.language();
  }
  pubFilter(data: any) { this.sbj.next(data); }
  obvFilter(): Subject<any> { return this.sbj; }
  getVoucherPublish(){
     return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        return this.http.get<any>(
          `${environment.APIUrl}/cart/voucher`, httpOptions
        );
      })
    );
 }
 getDataNotif(){
  return this.authService.token.pipe(
   take(1),
   switchMap(token => {

     const httpOptions = {
       headers: new HttpHeaders({
         'Authorization': `Bearer ${token}`
       })
     };
     let body = new FormData();
     return this.http.get<any>(
       `${environment.APIUrl}/cart/getnotif`, httpOptions
     );
   })
 );
}


  getDataUserCartSettings(){
     return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        return this.http.get<any>(
          `${environment.APIUrl}/cart/getdata`, httpOptions
        );
      })
    );
  }
  deleteAlamat(id){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
      let body = new FormData();
         body.append('id', id);
        return this.http.post<any>(
          `${environment.APIUrl}/user/delete_alamat`, body, httpOptions
        );
      })
    );

  }
  primaryAlamat(id){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
      let body = new FormData();
         body.append('id', id);
        return this.http.post<any>(
          `${environment.APIUrl}/user/primary_alamat`, body, httpOptions
        );
      })
    );

  }
  
  changeMemberPassword(newPass,currentPass){

    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
    body.append('new-password', newPass);
    body.append('current-password',currentPass);
        return this.http.post<any>(
          `${environment.APIUrl}/user/changepw`, body, httpOptions
        );
      })
    );

  }


  getCourier(){
     return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        return this.http.get<any>(
          `${environment.APIUrl}/cart/getCourier`, httpOptions
        );
      })
    );
  }
    beriUlasan(code, rating,review){
     return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('code',code);
        body.append('rating',rating);
        body.append('review',review);
        return this.http.post<any>(
          `${environment.APIUrl}/cart/reviewulasan`, body, httpOptions
        );
      })
    );
  }

  confirmTerimaBarang(code){
     return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('invoice',code);
        return this.http.post<any>(
          `${environment.APIUrl}/invoice/confirm_terima`, body, httpOptions
        );
      })
    );
  }


postVoucher(code){
     return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('voucher_code',code);
        return this.http.post<any>(
          `${environment.APIUrl}/cart/postdata`, body, httpOptions
        );
      })
    );
  }


  getBank(){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
          `${environment.APIUrl}/bank`, httpOptions
        );
      })
    );
  }
  updateUserCartSettings(user_address_id,expedition_id, voucher_code,shipping_price){
     return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('user_address_id',user_address_id);
        body.append('expedition_id',expedition_id);
        body.append('voucher_code',voucher_code);
        body.append('shipping_price',shipping_price);

        return this.http.post<any>(
          `${environment.APIUrl}/cart/postdata`, body, httpOptions
        );
      })
    );
  }
  getAlamat(id){
     return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        return this.http.get<any>(
          `${environment.APIUrl}/alamat/edit/${id}`, httpOptions
        );
      })
    );
  }
  getInvoiceDetail(code){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('code',code);

        return this.http.post<any>(
          `${environment.APIUrl}/cart/checkinvoice`, body, httpOptions
        );
      })
    );


  }
confirmPayment(noinvoice, namaRekening, transferDari, jumlahTransfer,nomorRekening, tanggalTransfer, attachment){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        let body = new FormData();
        body.append('noinvoice', noinvoice);
        body.append('namaRekening',namaRekening);
        body.append('transferDari',transferDari);
        body.append('jumlahTransfer',jumlahTransfer);
        body.append('nomorRekening',nomorRekening);
        body.append('tanggalTransfer',tanggalTransfer);
        body.append('attachment',attachment);

        return this.http.post<any>(
          `${environment.APIUrl}/user/konfirmasiPembayaran`, body, httpOptions
        );
      })
    );

  }
  faspayPaymentList(){
      return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        return this.http.get<any>(
          `${environment.HookUrl}/faspay/payment`, httpOptions
        );
      })
    );
  }
  updatePaymentSettings(paymentName,paymentCode){
    let body = new FormData();
        body.append('paymentName', paymentName);
        body.append('paymentCode',paymentCode);
    return this.authService.token.pipe(
    take(1),
    switchMap(token => {

      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      };
      return this.http.post<any>(
        `${environment.HookUrl}/faspay/update`, body,httpOptions
      );
    })
  );
}
  

  createOrder(insurance){
      return this.authService.token.pipe(
      take(1),
      switchMap(token => {

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('insurance',insurance);

        return this.http.post<any>(
          `${environment.APIUrl}/cart/createOrder`, body, httpOptions
        );
      })
    );
  }
}

