import { Component, OnInit } from '@angular/core';
import { Platform, ActionSheetController, AlertController, MenuController, ModalController, LoadingController } from '@ionic/angular';
import { ProductService }  from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { CartService }  from 'src/app/services/cart.service';

@Component({
  selector: 'app-modal-pilihpengiriman',
  templateUrl: './modal-pilihpengiriman.page.html',
  styleUrls: ['./modal-pilihpengiriman.page.scss'],
})
export class ModalPilihpengirimanPage implements OnInit {
voucherInput:any='';
voucherPublish:any=[];
userAddressId :any=0;
 constructor(
    public modalController: ModalController,
    private productService: ProductService,
    private route: ActivatedRoute,
    private loadingCtrl: LoadingController,
    private cartService: CartService,
    private alertCtrl: AlertController,
    private router: Router,

    ) {

  }
  ngOnInit() {
    this.loadingCtrl.create({ keyboardClose: true })
      .then(loading => {
        loading.present();
        this.cartService.getVoucherPublish().subscribe(res => {
            loading.dismiss();
             this.voucherPublish = res.data;
        });
      });
  }
  submitVoucher(){
      this.pilihVoucher(this.voucherInput);
  }
  pilihVoucher(code:any){
       this.loadingCtrl.create({ keyboardClose: true })
      .then(loading => {
        loading.present();
        this.cartService.postVoucher(code).subscribe(res => {
            loading.dismiss();
            if(res.success == 1){
                this.closeModal();
            }else{
                this.showAlert(res.message);

            }
        });
      });
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
       showAlert(message: string) {
    this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'custom-alert',
      message: message,
      buttons: ['OK']
    })
      .then(alertEl => alertEl.present());
  }

}
