// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
      lang: 'id',
      APIUrl:'https://staging-api.tokovapeku.com/api',
      HookUrl:'https://staging-api.tokovapeku.com/hook',
     DEFAULT_KEY:"iwPwdU7Lz179d2iJg8dm",
    SHARE_URL:'https://testing.tokovapeku.com',
   WEBSITE_NAME:"TOKOVAPEKU",
  firebase : {
    apiKey: "AIzaSyBKe_8EsmBWo9kLzAbO_piiNnPJuvTN4Hc",
    authDomain: "tokovapeku-8680e.firebaseapp.com",
    projectId: "tokovapeku-8680e",
    storageBucket: "tokovapeku-8680e.appspot.com",
    messagingSenderId: "504272975065",
    appId: "1:504272975065:web:abfc7c8b9d971d75a8bac8",
    measurementId: "G-E926KKTWGM"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
