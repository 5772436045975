import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, map, take, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from } from 'rxjs';
import { User } from '../models/user.model';
import { Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LanguageService } from './language.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user = new BehaviorSubject<User>(null);
  private _fcm = new BehaviorSubject<String>(null);
  language;

  constructor(
    private http: HttpClient,
    private router: Router,
    private languageService: LanguageService,
  ) {
    this.language = this.languageService.language();
  }

  get userIsAuthenticated() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user.token;
        } else {
          return false;
        }
      })
    );
  }

  get token() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.token;
        } else {
          return null;
        }
      })
    );
  }

  get user() {
    return this._user.asObservable();
  }

  login(email: string, password: string, fcm_token: any = '', uuid: string, os: string, os_version: string, model: string) {
    let body = new FormData();
    body.append('user', email);
    body.append('password', password);
    if (fcm_token != '') {
      body.append('fcm_token', fcm_token);
    }
    body.append('uuid', uuid);
    body.append('os', os);
    body.append('os_version', os_version);
    body.append('model', model);
    body.append('language', this.language);

    return this.http.post<any>(
      `${environment.APIUrl}/login`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        // tap(this.setUserData.bind(this))
      );
  }



  setUserData(data: any) {
    const user = new User(
      data.id,
      data.email,
      data.fullname,
      data.phone,
        data.status,
      data.token
    );


    this._user.next(user);

    const authData = JSON.stringify({
      id: data.id,
      email: data.email,
      fullname: data.fullname,
      phone: data.phone,
      status: data.status,
      token: data.token
    });

    Plugins.Storage.set({ key: 'authData', value: authData });
  }

  autoLogin() {
    return from(Plugins.Storage.get({ key: 'authData' })).pipe(
      map(storedData => {
        if (!storedData || !storedData.value) {
          return null;
        }
        const parsedData = JSON.parse(storedData.value) as {
          id: string;
          email: string;
          fullname: string;
          phone: string;
          status: string;
          token: string;
        };

        const user = new User(
          parsedData.id,
          parsedData.email,
          parsedData.fullname,
          parsedData.phone,
          parsedData.status,
          parsedData.token,
        );
        return user;
      }),
      tap(user => {
        if (user) {
          this._user.next(user);
        }
      }),
      map(user => {
        return !!user;
      })
    );
  }

  logout(type = 0) {
    this._user.next(null);
    Plugins.Storage.remove({ key: 'authData' });
    Plugins.Storage.remove({ key: 'token2' });
    if (type = 0) {
      this.router.navigateByUrl('/home');
      //window.location.reload()
    } else {
        window.location.reload();
        //window.top.location.href = 'https://ikf.co.id/';
    }
  }
  resetPassword(email){
    let body = new FormData();
    body.append('email', email);
    body.append('deepLink',environment.SHARE_URL);
    return this.http.post<any>(
      `${environment.APIUrl}/user/resetPassword`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        // tap(this.setUserData.bind(this))
      );
  }
  resetChangePassword(email,token,password,konfirmasi_password){
    let body = new FormData();
    body.append('email', email);
    body.append('token',token);
    body.append('password',password);
    body.append('konfirmasi_password',konfirmasi_password);
    return this.http.post<any>(
      `${environment.APIUrl}/user/resetChangePassword`,
      body
    )
      .pipe(
        map(resData => {
          return resData;
        }),
        // tap(this.setUserData.bind(this))
      );



  }

  logout_V2() {
    //    return this.token.pipe(
    //      take(1),
    //      switchMap(token => {
    //        const httpOptions = {
    //          headers: new HttpHeaders({
    //            'Authorization': `Bearer ${token}`
    //          })
    //        };
    //        return this.http.get<any>(
    //          `${environment.APIUrl}/api/logout`, httpOptions
    //        );
    //      })
    //    );
  }

  get fcm() {
    return this._fcm.asObservable();
  }


  setFcmToken(token) {
    this._fcm.next(token);
  }
}
